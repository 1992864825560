body
    width: 100%
    height: 100%
    margin: 0


#App
    width: 100%
    height: 100%
    min-height: 100vh
    overflow: auto
    position: relative

    max-width: 100vw
    overflow-x: hidden

    background-color: #f9f9f9
    font-family: "Noto Sans", sans-serif
    font-size: 14px
    color: #4a4a4a

.flex-wrap
    display: flex
    flex-flow: row nowrap
    justify-content: flex-start
    align-items: flex-start
    @media screen and (max-width: 1200px)
        flex-flow: row wrap

.flex-upload
    display: flex
    flex-flow: row nowrap
    justify-content: space-evenly
    @media screen and (max-width: 1200px)
        flex-flow: row wrap

.page-container
    width: 100%
    padding: 130px 10vw 100px
    box-sizing: border-box
    margin: 0 auto

    section
        margin-bottom: 90px
        &:last-of-type
            margin-bottom: 0
        header
            display: flex
            flex-flow: row nowrap
            justify-content: space-between
            margin-bottom: 21px
            @media screen and (min-width: 1350px)
                padding-right: 24px
            h2
                font-size: 26px
                font-weight: 400
                margin: 0
            h2 + .toggle
                font-size: 14px
                text-align: right
                color: #4a4a4a
                margin: 0
                padding-top: 8px
            h2 + p span
                    font-weight: bold
        .form-container
            border-radius: 3px
            background-color: #ffffff
            box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.1)

    &.history
        .flex-wrap
            .campaign-card picture
                background: white
                img
                    -webkit-filter: grayscale(100%)
                    filter: grayscale(1)
                    opacity: 0.5

    &.overview,
    &.history
        .flex-wrap
            display: flex
            flex-flow: row wrap
            justify-content: flex-start
            align-items: flex-start
            @media screen and (max-width: 1000px)
                flex-flow: column nowrap
            > div
                margin-right: 1%
                &:last-child
                    margin-right: auto
                @media screen and (max-width: 1350px)
                    width: 49%
                @media screen and (max-width: 1000px)
                    width: 100%
                &:nth-child(3n)
                    margin-right: 0
        .empty-section
            width: 100%
            margin: 90px 0 130px
            font-family: 'Noto Sans'
            font-size: 30px
            text-align: center
            color: rgba(140, 140, 140, 0.5)
