#copy
  position: absolute
  left: -100vw

.traffic
  .preview-link
    font-size: 14px

  .select-container,
  .url-container
    .tooltip
      padding: 4px 8px
      background-color: rgba(97, 97, 97, 0.9)
      color: white
      font-size: 10px
      border-radius: 4px
      opacity: 0
      transition: opacity .25s
      left: 50%
      transform: translate(-50%, -120%)

  .select-container
    align-items: flex-end

    > .form-group
      width: 600px
      margin: 0

      &:first-child
        margin: 0

    .warn-preset
      margin-left: .75rem
      display: flex
      align-items: center
      height: 38px

      a
        margin-left: .5rem
        font-size: 12px
        color: #9aa0ac
        text-decoration: underline

        &:hover
          color: #6e7687

      .warn-icon
        position: relative
        display: flex
        cursor: pointer
        color: #f1c40f

        &:hover .tooltip
          opacity: 1

      .tooltip
        min-width: 160px
        text-align: center

  .url-container
    display: flex
    align-items: center

    > .form-group
      flex-grow: 1
      margin: 0

      .form-label
        margin: 0

    .copy-btn
      margin-left: .75rem
      position: relative
      color: #9aa0ac

      &:hover
        color: #6e7687

        .tooltip
          opacity: 1
