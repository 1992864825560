.form
    display: flex
    flex-direction: column
    max-width: 50%

.input-form
    margin-top: 20px

.titleCard
    width: 270px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    cursor: pointer

.dco-link
    margin-bottom: .75rem

.dco-copy-btn
    cursor: pointer

    &:hover
        color: #6e7687
        + .tooltip
            opacity: 1

    &:active
        color: black

    + .tooltip
        padding: 4px 8px
        background-color: rgba(97, 97, 97, 0.9)
        color: white
        margin-left: -60px
        margin-top: 5px
        font-size: 10px
        border-radius: 4px
        opacity: 0
        transition: opacity .25s

.uppy-u-reset.uppy-Dashboard-Item-progressIndicator
    visibility: hidden
