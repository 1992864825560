body
    font-family: 'Roboto', sans-serif

a
    text-decoration: none

ul
    list-style: none
    padding: 0
    margin: 0

.icon
    margin-left: 5px
